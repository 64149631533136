import React, { useRef, useEffect } from "react"
import styled from "styled-components"

const BlockInput = ({
  placeholder = "",
  setValue = () => {},
  className = "",
  startingValue = null,
}) => {
  const val = useRef(null)

  const userInput = () => {
    setValue(val.current.value)
  }

  useEffect(() => {
    if (startingValue != null) {
      val.current.value = startingValue
    }
  }, [])

  return (
    <InputStyled
      className={className}
      placeholder={placeholder}
      ref={val}
      onChange={() => {
        userInput()
      }}
    />
  )
}

export default BlockInput

// ====================
//        STYLES
// ====================

const InputStyled = styled.textarea`
  border-radius: 5px;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  background-color: #f2f2f2;
  padding: 10px 20px;
  resize: none;

  ::placeholder {
    font: italic normal normal 16px Metropolis;
    color: #1f1f1f;
    line-height: revert;
  }
`
