import React, { useContext, useRef, useEffect } from "react"
import styled from "styled-components"
import WebWizardContext from "../../../contexts/web-wizard-context"
import Button from "../../reusable-components/generic-button"

import { segmentTrack } from "../../reusable-components/segment"

const WebWizardStep4 = ({ nextStep }) => {
  const {
    setSelectedStep,
    steps,
    selectedPackage,
    setSelectedPackage,
  } = useContext(WebWizardContext)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <ContentTitle>Available packages.</ContentTitle>
      <ContentDescription>
        Based on your answers we have these packages available.
      </ContentDescription>
      <ContentContainer>
        <PackageContainer>
          <Package
            className={selectedPackage === 0 ? "selected" : ""}
            onClick={() => {
              setSelectedPackage(0)
            }}
          >
            <PackageTitle className="package-title">Recommended</PackageTitle>
            <PackageUpperSection>
              <PackageName>eCommerce Enterprise 1</PackageName>
              <PackagePrice className="package-price">R85,000.00</PackagePrice>
              <PackageMaintenance>
                +monthly maintenance costs
              </PackageMaintenance>
            </PackageUpperSection>
            <PackageLowerSection>
              <PackageItem>Brand Identity</PackageItem>
              <PackageItem>Mobile Ready</PackageItem>
              <PackageItem>5 Payment Methods</PackageItem>
              <PackageItem>Enhanced Analytics</PackageItem>
              <PackageItem>On-Page SEO</PackageItem>
            </PackageLowerSection>
          </Package>
          <Package
            className={selectedPackage === 1 ? "selected" : ""}
            onClick={() => {
              setSelectedPackage(1)
            }}
          >
            <PackageTitle className="package-title">Upsize</PackageTitle>
            <PackageUpperSection>
              <PackageName>eCommerce Enterprise 2</PackageName>
              <PackagePrice className="package-price">R145,000.00</PackagePrice>
              <PackageMaintenance>
                +monthly maintenance costs
              </PackageMaintenance>
            </PackageUpperSection>
            <PackageLowerSection>
              <PackageItem>All Enterprise 1 Features</PackageItem>
              <PackageItem>Product Finder and Compare</PackageItem>
              <PackageItem>Unlimited Payment Methods</PackageItem>
              <PackageItem>Agility GIS Integration</PackageItem>
              <PackageItem>Custom Rebuild Frequency</PackageItem>
            </PackageLowerSection>
          </Package>
        </PackageContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button
          color="#1F2040"
          borderColor="#1F2040"
          hoverBackgroundColor="#FC3250"
          className="back"
          onClick={() => {
            segmentTrack("Step 4", {
              action: "User completed step 4",
              data: {
                verdict: "User does not like it",
              },
            })
            setSelectedStep(5)
            nextStep(steps[5])
          }}
        >
          Don’t like it…
        </Button>
        <Button
          color="#1F2040"
          borderColor="#1F2040"
          onClick={() => {
            segmentTrack("Step 4", {
              action: "User completed step 4",
              data: {
                verdict: "User is happy",
                package: selectedPackage === 0 ? "Recommended" : "Upsize",
              },
            })
            setSelectedStep(4)
            nextStep(steps[4])
          }}
        >
          I’m happy!
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default WebWizardStep4

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0 20px;
`
const ContentTitle = styled.h1`
  font: normal normal bold 38px/46px Metropolis;
  color: #1f2040;
  width: 100%;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font: normal normal bold 28px/34px Metropolis;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    text-align: center;
  }
`
const ContentDescription = styled.h2`
  font: normal normal normal 21px/35px Metropolis;
  color: #167ffc;
  width: 100%;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font: normal normal normal 16px/30px Metropolis;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    text-align: center;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;

  & > div {
    margin: 10px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    .back {
      order: 2;
    }
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  flex-wrap: wrap;
`
const PackageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const Package = styled.div`
  display: flex;
  flex-direction: column;
  width: 367px;
  height: 411px;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
  border: 1px solid #f5f5f5;
  transition: all 0.3s ease-out;

  &.selected {
    border: 1px solid #167ffc;

    .package-title {
      background-color: #167ffc;
      color: white;
    }
    .package-price {
      color: #167ffc;
    }
  }

  &:not(.selected):hover {
    border: 1px solid rgb(221, 221, 221);
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    margin-bottom: 50px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    width: 292px;
  }
`
const PackageTitle = styled.div`
  width: 100%;
  height: 50px;
  background-color: #f5f5f5;
  color: #1f2040;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 16px/19px Metropolis;
  border-radius: 3px 3px 0 0;
  transition: all 0.3s ease-out;
`
const PackageUpperSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 30px;
`
const PackageName = styled.h2`
  font: normal normal normal 21px/25px Metropolis;
  color: #1f2040;
  text-align: center;
  width: 100%;
  margin: 0;
  margin-bottom: 15px;
`

const PackagePrice = styled.h2`
  color: #1f2040;
  font: normal normal bold 28px/34px Metropolis;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  transition: all 0.3s ease-out;
`

const PackageMaintenance = styled.h4`
  color: #1f2040;
  font: italic normal 300 14px/17px Metropolis;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
`

const PackageLowerSection = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`

const PackageItem = styled.h4`
  color: #1f2040;
  font: normal normal normal 16px/19px Metropolis;
  text-align: center;
  width: 100%;
  margin: 10px 0;
`
