import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import WebWizardContext from "../../../contexts/web-wizard-context"
import Button from "../../reusable-components/generic-button"
import Input from "../../reusable-components/input"
import DateInput from "../../reusable-components/date-input"
import TimeInput from "../../reusable-components/time-input"

import { segmentTrack } from "../../reusable-components/segment"

const WebWizardStep5 = ({ nextStep, mobile = false }) => {
  const {
    setSelectedStep,
    steps,
    step1Type,
    meetingDate,
    setMeetingDate,
    meetingTime,
    setMeetingTime,
    clientName,
    setClientName,
    clientEmail,
    setClientEmail,
    clientPhone,
    setClientPhone,
  } = useContext(WebWizardContext)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <ContentTitle>Great to hear!</ContentTitle>
      <ContentDescription>Schedule a meeting with us.</ContentDescription>
      <ContentContainer>
        <Title>Select a preferred date and time</Title>
        <DateTime>
          <DateInput
            startingValue={meetingDate}
            setValue={setMeetingDate}
            placeholder="Preffered date"
          ></DateInput>
          <TimeInput
            startingValue={meetingTime}
            setValue={setMeetingTime}
            placeholder="Preffered time"
          ></TimeInput>
        </DateTime>
        {meetingTime == null || meetingDate == null ? null : (
          <>
            <Title>Let's get in touch</Title>
            <Input
              startingValue={clientName}
              setValue={setClientName}
              placeholder="What should we call you?"
            ></Input>
            <Contact>
              <Input
                startingValue={clientEmail}
                setValue={setClientEmail}
                placeholder="Email address"
              ></Input>
              <Input
                startingValue={clientPhone}
                setValue={setClientPhone}
                placeholder="Phone number"
              ></Input>
            </Contact>
          </>
        )}
      </ContentContainer>
      <ButtonContainer>
        <Button
          color="#1F2040"
          borderColor="#1F2040"
          hoverBackgroundColor="#FC3250"
          className="back"
          onClick={() => {
            if (step1Type === 2) {
              setSelectedStep(0)
              nextStep(steps[0])
            } else {
              setSelectedStep(2)
              nextStep(steps[2])
            }
          }}
        >
          Back
        </Button>
        <Button
          className={
            clientPhone == null ||
            clientPhone == "" ||
            clientEmail == null ||
            clientEmail == "" ||
            clientName == null ||
            clientName == ""
              ? "disabled"
              : ""
          }
          color="#1F2040"
          borderColor="#1F2040"
          onClick={() => {
            segmentTrack("Step 5", {
              action: "User completed step 5",
              data: {
                meetingDate: meetingDate,
                meetingTime: meetingTime,
                clientName: clientName,
                clientEmail: clientEmail,
                clientPhone: clientPhone,
              },
            })
            setSelectedStep(6)
            nextStep(steps[6])
          }}
        >
          Let's do it!
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default WebWizardStep5

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  padding-right: 20px;

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    padding-left: 10px;
  }
`
const ContentTitle = styled.h1`
  font: normal normal bold 38px/46px Metropolis;
  color: #1f2040;
  width: 100%;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font: normal normal bold 28px/34px Metropolis;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    text-align: center;
  }
`
const ContentDescription = styled.h2`
  font: normal normal normal 21px/35px Metropolis;
  color: #167ffc;
  width: 100%;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font: normal normal normal 16px/30px Metropolis;=
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    text-align: center;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;

  & > div {
    margin: 10px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    .back {
      order: 2;
    }
  }
`

const Title = styled.h4`
  font: normal normal normal 16px/19px Metropolis;
  color: #1f2040;
  margin-bottom: 15px;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  flex-wrap: wrap;

  & > input {
    margin-right: 20px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    align-items: center;
    justify-content: center;

    & > input {
      margin-right: 0;
    }
  }
`
const DateTime = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 50px;

  & > input {
    margin-right: 20px;
  }

  .hidden {
    opacity: 0;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    flex-wrap: wrap;
    justify-content: center;

    & > input {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
`
const Contact = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 50px;

  .hidden {
    opacity: 0;
  }

  & > input {
    margin-right: 20px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    flex-wrap: wrap;
    justify-content: center;

    & > input {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
`
