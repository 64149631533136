import React, { useEffect, useRef } from "react"
import styled from "styled-components"

const TimeInput = ({
  placeholder = "",
  setValue = () => {},
  className = "",
  startingValue = null,
}) => {
  const val = useRef(null)

  useEffect(() => {
    if (startingValue != null) {
      val.current.value = startingValue
    }
  }, [])

  const userInput = () => {
    setValue(val.current.value)
  }
  return (
    <InputStyled
      className={className}
      type="time"
      placeholder={placeholder}
      ref={val}
      onChange={() => {
        userInput()
      }}
    />
  )
}

export default TimeInput

// ====================
//        STYLES
// ====================

const InputStyled = styled.input`
  border-radius: 5px;
  height: 50px;
  width: 100%;
  outline: none;
  border: none;
  background-color: #f2f2f2;
  padding: 0 20px;

  ::placeholder {
    font: italic normal normal 16px Metropolis;
    color: #1f1f1f;
    line-height: revert;
  }
`
