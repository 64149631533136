import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import WebWizardContext from "../../../contexts/web-wizard-context"

import { navigate } from "gatsby"

const WebWizardSuccess = ({ setModalOpen, mobile = false }) => {
  const { clearState } = useContext(WebWizardContext)

  useEffect(() => {
    setTimeout(exit, 3000)
  }, [])

  const exit = () => {
    if (mobile === true) {
      navigate("/")
    } else {
      clearState()
      setModalOpen(false)
    }
  }

  return (
    <Container>
      <ContentTitle>Thank you!</ContentTitle>
      <ContentDescription>
        You'll be directed back to the eCommerce page
      </ContentDescription>
    </Container>
  )
}

export default WebWizardSuccess

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const ContentTitle = styled.h1`
  font: normal normal bold 38px/46px Metropolis;
  color: #1f2040;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    margin-top: 60px;
  }

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font: normal normal bold 28px/34px Metropolis;
    text-align: center;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    margin-top: 150px;
  }
`
const ContentDescription = styled.h2`
  font: normal normal normal 21px/35px Metropolis;
  color: #167ffc;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font: normal normal normal 16px/30px Metropolis;
    text-align: center;
  }
`
