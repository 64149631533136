import React, { useEffect, useRef } from "react"
import styled from "styled-components"

const NumberInput = ({
  placeholder = "",
  setValue = () => {},
  startingValue = null,
}) => {
  const val = useRef(null)

  const userInput = () => {
    setValue(val.current.value)
  }

  useEffect(() => {
    if (startingValue != null) {
      val.current.value = startingValue
    }
  }, [])

  return (
    <InputStyled
      ref={val}
      type="number"
      placeholder={placeholder}
      onChange={() => {
        userInput()
      }}
    />
  )
}

export default NumberInput

// ====================
//        STYLES
// ====================

const InputStyled = styled.input`
  border-radius: 5px;
  height: 50px;
  width: 100px;
  outline: none;
  border: 1px solid black;
  background-color: white;
  padding: 0 7px 0 20px;

  ::placeholder {
    font: italic normal normal 16px Metropolis;
    color: #1f1f1f;
    line-height: revert;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */

    &[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }
  }
`
