import React, { useState } from "react"
import WebWizardStep1 from "../components/homepage/web-wizard/web-wizard-step-1"
import WebWizardStep2 from "../components/homepage/web-wizard/web-wizard-step-2"
import WebWizardStep3 from "../components/homepage/web-wizard/web-wizard-step-3"
import WebWizardStep4 from "../components/homepage/web-wizard/web-wizard-step-4"
import WebWizardStep5 from "../components/homepage/web-wizard/web-wizard-step-5"
import WebWizardStep6 from "../components/homepage/web-wizard/web-wizard-step-6"
import WebWizardSuccess from "../components/homepage/web-wizard/web-wizard-success"
import WebWizardContext from "./web-wizard-context"

const WebWizardProvider = ({ children }) => {
  const [selectedStep, setSelectedStep] = useState(0)

  const steps = [
    {
      component: WebWizardStep1,
    },
    {
      component: WebWizardStep2,
    },
    {
      component: WebWizardStep3,
    },
    {
      component: WebWizardStep4,
    },
    {
      component: WebWizardStep5,
    },
    {
      component: WebWizardStep6,
    },
    {
      component: WebWizardSuccess,
    },
  ]
  const [stepComponent, setStepComponent] = useState(steps[0])

  const [step1Type, setStep1Type] = useState(null)
  const [selectedIndustry, setSelectedIndustry] = useState(
    "What industry are you in?"
  )

  const reasons = ["Reason 1", "Reason 2", "Reason 3", "Want sommer"]

  const [shippingOptions, setShippingOptions] = useState([])
  const [numberOfProducts, setNumberOfProducts] = useState(null)
  const [haveCategories, setHaveCategories] = useState(false)
  const [paymentOptions, setPaymentOptions] = useState([])
  const [custom, setCustom] = useState(false)
  const [pages, setPages] = useState([])
  const [extraPages, setExtraPages] = useState(null)
  const [selectedPackage, setSelectedPackage] = useState(0)
  const [meetingDate, setMeetingDate] = useState(null)
  const [meetingTime, setMeetingTime] = useState(null)
  const [clientName, setClientName] = useState(null)
  const [clientEmail, setClientEmail] = useState(null)
  const [clientPhone, setClientPhone] = useState(null)
  const [selectedReason, setSelectedReason] = useState("Select a reason")
  const [userComment, setUserComment] = useState(null)

  const clearState = () => {
    setSelectedStep(0)
    setStepComponent(steps[0])
    setStep1Type(null)
    setSelectedIndustry("What industry are you in?")
    setShippingOptions([])
    setNumberOfProducts(null)
    setHaveCategories(false)
    setPaymentOptions([])
    setCustom(false)
    setPages([])
    setSelectedPackage(0)
    setMeetingDate(null)
    setMeetingTime(null)
    setClientName(null)
    setClientEmail(null)
    setClientPhone(null)
    setSelectedReason("Select a reason")
    setUserComment(null)
    setExtraPages(null)
  }

  const pagesOptions = [
    "Home page",
    "Store page",
    "Cart",
    "About us",
    "Categories page",
    "Wishlist",
    "Contact us",
    "Brands page",
    "Checkout",
    "Blog",
    "Products/Services page",
    "Order page",
    "Portfolio/Showcase",
    "Profile page",
    "Ts&Cs/Policy",
    "Other pages?",
  ]

  return (
    <WebWizardContext.Provider
      value={{
        selectedStep,
        setSelectedStep,
        steps,
        step1Type,
        setStep1Type,
        selectedIndustry,
        setSelectedIndustry,
        shippingOptions,
        setShippingOptions,
        numberOfProducts,
        setNumberOfProducts,
        haveCategories,
        setHaveCategories,
        paymentOptions,
        setPaymentOptions,
        custom,
        setCustom,
        pages,
        setPages,
        pagesOptions,
        selectedPackage,
        setSelectedPackage,
        meetingDate,
        setMeetingDate,
        meetingTime,
        setMeetingTime,
        clientName,
        setClientName,
        clientEmail,
        setClientEmail,
        clientPhone,
        setClientPhone,
        clearState,
        stepComponent,
        setStepComponent,
        reasons,
        selectedReason,
        setSelectedReason,
        userComment,
        setUserComment,
        extraPages,
        setExtraPages,
      }}
    >
      {children}
    </WebWizardContext.Provider>
  )
}

export default WebWizardProvider
