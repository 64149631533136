import React, { useContext, useEffect, useRef } from "react"
import styled from "styled-components"
import WebWizardContext from "../../../contexts/web-wizard-context"
import Button from "../../reusable-components/generic-button"
import SwitchButton from "../../reusable-components/switch-button"
import NumberInput from "../../reusable-components/number-input"
import Checkbox from "../../reusable-components/checkbox"

import { segmentTrack } from "../../reusable-components/segment"

const WebWizardStep2 = ({ nextStep }) => {
  const {
    setSelectedStep,
    steps,
    shippingOptions,
    setShippingOptions,
    numberOfProducts,
    setNumberOfProducts,
    haveCategories,
    setHaveCategories,
    paymentOptions,
    setPaymentOptions,
  } = useContext(WebWizardContext)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const updateShippingOptions = (option) => {
    if (shippingOptions.includes(option)) {
      setShippingOptions((prevState) =>
        prevState.filter((val) => {
          return val != option
        })
      )
    } else {
      setShippingOptions((prevState) => [...prevState, option])
    }
  }

  const updatePaymentOptions = (option) => {
    if (paymentOptions.includes(option)) {
      setPaymentOptions((prevState) =>
        prevState.filter((val) => {
          return val != option
        })
      )
    } else {
      setPaymentOptions((prevState) => [...prevState, option])
    }
  }

  return (
    <Container>
      <ContentTitle>Services you offer.</ContentTitle>
      <ContentDescription>
        Do you want a standard design or complete custom?
      </ContentDescription>
      <ContentContainer>
        <Title>How many products do you have?</Title>
        <NumberInput
          setValue={setNumberOfProducts}
          startingValue={numberOfProducts}
        ></NumberInput>
        {numberOfProducts == null || numberOfProducts == "" ? null : (
          <>
            <Title style={{ marginTop: "40px" }}>Do you have categories?</Title>
            <SwitchButton
              buttonState={haveCategories}
              setButtonState={setHaveCategories}
            ></SwitchButton>
          </>
        )}
        {numberOfProducts == null || numberOfProducts == "" ? null : (
          <>
            <Title style={{ marginTop: "40px" }}>
              Which shipping options do you have?
            </Title>
            <ButtonContainer className="left">
              <Button
                className={
                  shippingOptions.includes("Delivery") ? "selected" : ""
                }
                color="#1F2040"
                borderColor="#1F2040"
                onClick={() => {
                  updateShippingOptions("Delivery")
                }}
              >
                Delivery
              </Button>
              <Button
                className={
                  shippingOptions.includes("Collection") ? "selected" : ""
                }
                color="#1F2040"
                borderColor="#1F2040"
                onClick={() => {
                  updateShippingOptions("Collection")
                }}
              >
                Collection
              </Button>
            </ButtonContainer>
          </>
        )}
        {numberOfProducts == null ||
        numberOfProducts == "" ||
        shippingOptions.length === 0 ? null : (
          <>
            <Title style={{ marginTop: "40px" }}>
              Which payment methods do you have?
            </Title>
            <PaymentOptionsContainer>
              <PaymentOption>
                <Checkbox
                  startingState={
                    paymentOptions.includes("EFT") ? "checked" : "unchecked"
                  }
                  onClick={() => {
                    updatePaymentOptions("EFT")
                  }}
                ></Checkbox>
                <PaymentOptionTitle>EFT</PaymentOptionTitle>
              </PaymentOption>
              <PaymentOption>
                <Checkbox
                  startingState={
                    paymentOptions.includes("Credit Card")
                      ? "checked"
                      : "unchecked"
                  }
                  onClick={() => {
                    updatePaymentOptions("Credit Card")
                  }}
                ></Checkbox>
                <PaymentOptionTitle>Credit Card</PaymentOptionTitle>
              </PaymentOption>
              <PaymentOption>
                <Checkbox
                  startingState={
                    paymentOptions.includes("Scan") ? "checked" : "unchecked"
                  }
                  onClick={() => {
                    updatePaymentOptions("Scan")
                  }}
                ></Checkbox>
                <PaymentOptionTitle>Scan</PaymentOptionTitle>
              </PaymentOption>
              <PaymentOption>
                <Checkbox
                  startingState={
                    paymentOptions.includes("Debit Order")
                      ? "checked"
                      : "unchecked"
                  }
                  onClick={() => {
                    updatePaymentOptions("Debit Order")
                  }}
                ></Checkbox>
                <PaymentOptionTitle>Debit Order</PaymentOptionTitle>
              </PaymentOption>
              <PaymentOption>
                <Checkbox
                  startingState={
                    paymentOptions.includes("Laybuy") ? "checked" : "unchecked"
                  }
                  onClick={() => {
                    updatePaymentOptions("Laybuy")
                  }}
                ></Checkbox>
                <PaymentOptionTitle>Laybuy</PaymentOptionTitle>
              </PaymentOption>
            </PaymentOptionsContainer>
          </>
        )}
      </ContentContainer>
      <ButtonContainer>
        <Button
          color="#1F2040"
          borderColor="#1F2040"
          hoverBackgroundColor="#FC3250"
          className="back"
          onClick={() => {
            setSelectedStep(0)
            nextStep(steps[0])
          }}
        >
          Back
        </Button>
        <Button
          className={
            numberOfProducts == null ||
            numberOfProducts == "" ||
            shippingOptions.length === 0 ||
            paymentOptions.length == 0
              ? "disabled"
              : ""
          }
          color="#1F2040"
          borderColor="#1F2040"
          onClick={() => {
            setSelectedStep(2)
            nextStep(steps[2])
            segmentTrack("Step 2", {
              action: "User completed step 2",
              data: {
                numberOfProducts: numberOfProducts,
                haveCategories: haveCategories,
                shippingOptions: shippingOptions,
                paymentOptions: paymentOptions,
              },
            })
          }}
        >
          Next
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default WebWizardStep2

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0 20px;
`
const ContentTitle = styled.h1`
  font: normal normal bold 38px/46px Metropolis;
  color: #1f2040;
  width: 100%;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font: normal normal bold 28px/34px Metropolis;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    text-align: center;
  }
`
const ContentDescription = styled.h2`
  font: normal normal normal 21px/35px Metropolis;
  color: #167ffc;
  width: 100%;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font: normal normal normal 16px/30px Metropolis;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    text-align: center;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;

  & > div {
    margin: 10px;
  }

  &.left {
    justify-content: flex-start;
    margin-bottom: 50px;

    @media (max-width: ${({ theme }) => theme.tabletLarge}) {
      justify-content: center;
    }

    @media (max-width: ${({ theme }) => theme.mobileLarge}) {
      margin-bottom: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    .back {
      order: 2;
    }

    &:not(.left) {
      margin-top: 50px;
    }
  }
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    align-items: center;
  }
`

const Title = styled.h4`
  font: normal normal normal 16px/19px Metropolis;
  color: #1f2040;
  margin-bottom: 15px;
`

const PaymentOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
`

const PaymentOptionTitle = styled.h4`
  font: normal normal normal 16px/19px Metropolis;
  color: #1f2040;
  margin: 0;
`

const PaymentOption = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`
