import React from "react"
import styled from "styled-components"

const SwitchButton = ({ className = "", buttonState, setButtonState }) => {
  return (
    <ButtonOuter
      className={className}
      onClick={() => {
        setButtonState(buttonState == false ? true : false)
      }}
    >
      <ButtonInner
        className={buttonState == false ? "unchecked" : "checked"}
      ></ButtonInner>
    </ButtonOuter>
  )
}

export default SwitchButton

// ====================
//        STYLES
// ====================

const ButtonOuter = styled.div`
  display: flex;
  width: 65px;
  height: 30px;
  margin: 10px;
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.darkGrey};
  cursor: pointer;
`
const ButtonInner = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: ${({ theme }) => theme.brightRed};
  position: relative;
  left: -1px;
  top: -1px;
  transition: all 0.3s ease-out;

  &.checked {
    left: 34px;
    background: ${({ theme }) => theme.brightBlue};
  }
`
