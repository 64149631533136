import React from "react"

const IconChevronDown = ({
  fill = "none",
  stroke = "#141626",
  className = "",
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="20.828"
    height="11.414"
    viewBox="0 0 20.828 11.414"
  >
    <path
      fill={fill}
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2px"
      d="M9,13.5l9,9,9-9"
      transform="translate(-7.586 -12.086)"
    />
  </svg>
)

export default IconChevronDown
