import React from "react"
import styled from "styled-components"

const Button = ({
  onClick = () => {},
  className = "",
  text = "",
  children = null,
  color = "white",
  backgroundColor = "none",
  hoverBackgroundColor = "#167FFC",
  borderColor = "white",
}) => {
  return (
    <ButtonOuter
      className={className}
      onClick={() => {
        if (className === "disabled") {
        } else {
          onClick()
        }
      }}
      color={color}
      backgroundColor={backgroundColor}
      hoverBackgroundColor={hoverBackgroundColor}
      borderColor={borderColor}
    >
      {text || children}
    </ButtonOuter>
  )
}

export default Button

// ====================
//        STYLES
// ====================

const ButtonOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 239px;
  height: 50px;
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 5px;
  color: ${({ color }) => color};
  font: normal normal normal 16px/16px Metropolis;
  transition: all 0.3s ease-out;
  background-color: ${({ backgroundColor }) => backgroundColor};

  &:not(.disabled) {
    cursor: pointer;
  }

  &:not(.disabled):hover {
    color: white;
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
    border-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
  }

  &.selected {
    color: white;
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
    border-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
  }

  &.disabled {
    opacity: 0.5;
  }
`
