import React, { useContext, useRef, useEffect } from "react"
import styled from "styled-components"
import WebWizardContext from "../../../contexts/web-wizard-context"
import Button from "../../reusable-components/generic-button"
import Checkbox from "../../reusable-components/checkbox"
import BlockInput from "../../reusable-components/block-input"

import { segmentTrack } from "../../reusable-components/segment"

const WebWizardStep3 = ({ nextStep }) => {
  const {
    setSelectedStep,
    steps,
    custom,
    setCustom,
    pages,
    setPages,
    pagesOptions,
    extraPages,
    setExtraPages,
  } = useContext(WebWizardContext)

  const updatePages = (option) => {
    if (pages.includes(option)) {
      setPages((prevState) =>
        prevState.filter((val) => {
          return val != option
        })
      )
    } else {
      setPages((prevState) => [...prevState, option])
    }
  }

  useEffect(() => {
    if (!pages.includes("Other pages?")) {
      setExtraPages(null)
    }
  }, [pages])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <ContentTitle>Design.</ContentTitle>
      <ContentDescription>Your offering to customers.</ContentDescription>
      <ContentContainer>
        <ButtonContainer className="left">
          <Button
            className={custom === true ? "selected" : ""}
            color="#1F2040"
            borderColor="#1F2040"
            onClick={() => {
              setCustom(true)
            }}
          >
            Standard
          </Button>
          <Button
            color="#1F2040"
            borderColor="#1F2040"
            onClick={() => {
              setCustom(false)
              segmentTrack("Step 3", {
                action: "User completed step 3",
                data: {
                  siteType: "Custom",
                },
              })
              setSelectedStep(4)
              nextStep(steps[4])
            }}
          >
            Custom
          </Button>
        </ButtonContainer>
        {custom == false ? null : (
          <>
            <Title>Select the pages you need</Title>
            <PaymentOptionsContainer>
              {pagesOptions.map((value, index) => (
                <PaymentOption key={index}>
                  <Checkbox
                    startingState={
                      pages.includes(value) ? "checked" : "unchecked"
                    }
                    onClick={() => {
                      updatePages(value)
                    }}
                  ></Checkbox>
                  <PaymentOptionTitle>{value}</PaymentOptionTitle>
                </PaymentOption>
              ))}
              {pages.includes("Other pages?") ? (
                <PaymentOption>
                  <TextBlockContainer>
                    <BlockInput
                      startingValue={extraPages}
                      placeholder="What other pages do you need?"
                      setValue={setExtraPages}
                    ></BlockInput>
                  </TextBlockContainer>
                </PaymentOption>
              ) : null}
            </PaymentOptionsContainer>
          </>
        )}
      </ContentContainer>
      <ButtonContainer>
        <Button
          color="#1F2040"
          borderColor="#1F2040"
          hoverBackgroundColor="#FC3250"
          className="back"
          onClick={() => {
            setSelectedStep(1)
            nextStep(steps[1])
          }}
        >
          Back
        </Button>
        <Button
          color="#1F2040"
          borderColor="#1F2040"
          onClick={() => {
            setSelectedStep(3)
            nextStep(steps[3])
            segmentTrack("Step 3", {
              action: "User completed step 3",
              data: {
                siteType: "Standard",
                pagesNeeded: pages,
                extraPages: extraPages,
              },
            })
          }}
        >
          Next
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default WebWizardStep3

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0 20px;
`
const ContentTitle = styled.h1`
  font: normal normal bold 38px/46px Metropolis;
  color: #1f2040;
  width: 100%;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font: normal normal bold 28px/34px Metropolis;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    text-align: center;
  }
`
const ContentDescription = styled.h2`
  font: normal normal normal 21px/35px Metropolis;
  color: #167ffc;
  width: 100%;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font: normal normal normal 16px/30px Metropolis;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    text-align: center;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;

  & > div {
    margin: 10px;
  }

  &.left {
    justify-content: flex-start;
    margin-bottom: 50px;

    @media (max-width: ${({ theme }) => theme.tabletLarge}) {
      justify-content: center;
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    .back {
      order: 2;
    }
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    align-items: center;
  }
`

const Title = styled.h4`
  font: normal normal normal 16px/19px Metropolis;
  color: #1f2040;
  margin-bottom: 15px;
`

const PaymentOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
`

const PaymentOptionTitle = styled.h4`
  font: normal normal normal 16px/19px Metropolis;
  color: #1f2040;
  margin: 0;
`

const PaymentOption = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  width: 220px;

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    width: 100%;
  }
`
const TextBlockContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 100px;
  display: flex;
  padding-left: 10px;
`
