import React, { useContext, useEffect, useRef } from "react"
import styled from "styled-components"
import WebWizardContext from "../../../contexts/web-wizard-context"
import Button from "../../reusable-components/generic-button"
import DropdownSelect from "../../reusable-components/dropdown-select"
import roiData from "../../../data/roiData.json"

import { segmentTrack } from "../../reusable-components/segment"

const WebWizardStep1 = ({ nextStep }) => {
  const {
    setSelectedStep,
    steps,
    step1Type,
    setStep1Type,
    selectedIndustry,
    setSelectedIndustry,
  } = useContext(WebWizardContext)

  const types = ["To sell products", "To provide services", "Something else"]

  const bottomRef = useRef(null)

  useEffect(() => {
    if (step1Type === 0 || step1Type === 1) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [step1Type])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container id="step-1-container">
      <ContentTitle>Awesome! Use our Web-Wizard.</ContentTitle>
      <ContentDescription>
        What kind of site do you need? <br /> This is the type of core eCommerce
        you require.
      </ContentDescription>
      <ContentContainer>
        <Type>
          <Button
            className={step1Type === 0 ? "selected" : ""}
            color="#1F2040"
            borderColor="#1F2040"
            onClick={() => {
              setStep1Type(0)
            }}
          >
            To sell products
          </Button>
          <TypeDescription>
            You sell tangible products that users can buy and are delivered and
            collected.
          </TypeDescription>
        </Type>
        <Type>
          <Button
            className={step1Type === 1 ? "selected" : ""}
            color="#1F2040"
            borderColor="#1F2040"
            onClick={() => {
              setStep1Type(1)
            }}
          >
            To provide services
          </Button>
          <TypeDescription>
            You provide online services that can be sold to a user through a
            digital interface.
          </TypeDescription>
        </Type>
        <Type>
          <Button
            color="#1F2040"
            borderColor="#1F2040"
            onClick={() => {
              setStep1Type(2)
              setSelectedStep(4)
              nextStep(steps[4])
            }}
          >
            Something else
          </Button>
          <TypeDescription>
            You need another kind of site or you’re not sure.
          </TypeDescription>
        </Type>
      </ContentContainer>
      <DropdownContainer>
        {step1Type === 0 || step1Type === 1 ? (
          <DropdownSelect
            className="dropdown"
            selectOptions={Object.keys(roiData).map((value) => value)}
            selectedOption={selectedIndustry}
            setSelectedOption={setSelectedIndustry}
          ></DropdownSelect>
        ) : null}
      </DropdownContainer>
      <ButtonContainer ref={bottomRef}>
        <Button
          className={
            (step1Type === 0 || step1Type === 1) &&
            selectedIndustry !== "What industry are you in?"
              ? ""
              : "disabled"
          }
          color="#1F2040"
          borderColor="#1F2040"
          onClick={() => {
            setSelectedStep(1)
            nextStep(steps[1])
            segmentTrack("Step 1", {
              action: "User completed step 1",
              data: {
                type: types[step1Type],
                industry: selectedIndustry,
              },
            })
          }}
        >
          Next
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default WebWizardStep1

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0 20px;
`
const ContentTitle = styled.h1`
  font: normal normal bold 38px/46px Metropolis;
  color: #1f2040;
  width: 100%;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font: normal normal bold 28px/34px Metropolis;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    text-align: center;
  }
`
const ContentDescription = styled.h2`
  font: normal normal normal 21px/35px Metropolis;
  color: #167ffc;
  width: 100%;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font: normal normal normal 16px/30px Metropolis;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    text-align: center;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  & > div {
    margin: 10px;
  }
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  // flex-grow: 1;
  justify-content: center;
  flex-wrap: wrap;
`
const Type = styled.div`
  width: 239px;
  display: flex;
  flex-direction: column;
  margin: 10px;
`
const TypeDescription = styled.h3`
  font: normal normal normal 16px/30px Metropolis;
  width: 100%;
  color: #1f1f1f;
  margin-top: 20px;
`
const DropdownContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  flex-grow: 1;

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    margin-bottom: 20px;
  }
`
