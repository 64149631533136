import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import ChevronDownIcon from "../../data/images/icons/chevron-down"

const SelectInput = ({
  width = "650px",
  selectOptions = [],
  selectedOption = "",
  setSelectedOption = () => {},
  className = "",
}) => {
  const [dropdownState, setDropdownState] = useState("collapsed")

  const handleSelect = (event) => {
    setSelectedOption(event.target.textContent)
    setDropdownState("collapsed")
  }

  // Ref to select container to collapse it when clicking outside it
  const node = useRef()

  // useEffect to add click event listener to collapse select
  useEffect(() => {
    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  const handleClick = (event) => {
    if (!node.current.contains(event.target)) {
      setDropdownState("collapsed")
    }
  }

  return (
    <SelectComponentWrapper ref={node} width={width} className={className}>
      <SelectInputContainer
        className={dropdownState}
        onClick={() => {
          setDropdownState(
            dropdownState == "collapsed" ? "expanded" : "collapsed"
          )
        }}
      >
        <StyledSelectInput>{selectedOption}</StyledSelectInput>
        <ChevronDown />
      </SelectInputContainer>
      <SelectDropdown className={dropdownState}>
        {selectOptions.map((value, index) => (
          <StyledSelectOption value={index} key={index} onClick={handleSelect}>
            {value}
          </StyledSelectOption>
        ))}
      </SelectDropdown>
    </SelectComponentWrapper>
  )
}

export default SelectInput

// ===============
//     STYLES
// ===============

const SelectComponentWrapper = styled.div`
  width: ${({ width }) => width};
  position: relative;
  color: #1f2040;
  font-family: Metropolis;
  font-size: 16px;
  line-height: 16px;
  margin: 25px 0;
  cursor: pointer;
`
const SelectInputContainer = styled.div`
  background-color: #f2f2f2;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 5px;

  &.expanded {
    border-radius: 5px 5px 0 0;
  }

  &.collapsed:hover {
    background-color: #fc3250;
    color: white;

    svg {
      path {
        stroke: white;
      }
    }
  }
`
const StyledSelectInput = styled.div`
  display: flex;
  align-items: center;
  width 200px;
  margin-left: 18px;
`
const ChevronDown = styled(ChevronDownIcon)`
  margin-left: auto;
  margin-right: 20px;
  width: 20px;
`
const SelectDropdown = styled.div`
  z-index: 999;
  position: absolute;
  height: fit-content;
  width: 100%;
  border-radius: 0 0 5px 5px;
  color: #1f2040;
  overflow: hidden;
  border: 1px solid #dddddd;

  &.collapsed {
    display: none;
  }
`
const StyledSelectOption = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  padding-left: 18px;
  background-color: white;
  border-top: 1px solid #dddddd;

  &:hover {
    background-color: #fc3250;
    color: white;
  }
`
