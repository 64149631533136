export const segmentTrack = (label, payload) => {
  // console.log("Running track function", label)

  const isBrowser = () => typeof window !== "undefined"

  if (!isBrowser()) return
  // Console log data that will be sent
  try {
    if (window.analytics != null) {
      // Call the segment script tracking function
      window.analytics.track(label, payload)
    } else {
      console.log(`segment: [${label}] FAILED, analytics function not valid`)
    }
  } catch {
    console.log(`segment: [${label}] FAILED, exception thrown`)
  }
}
