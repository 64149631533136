import React, { useContext } from "react"
import styled from "styled-components"
import WebWizardContext from "../../../contexts/web-wizard-context"
import Button from "../../reusable-components/generic-button"
import DropdownSelect from "../../reusable-components/dropdown-select"
import BlockInput from "../../reusable-components/block-input"

import { segmentTrack } from "../../reusable-components/segment"

const WebWizardStep6 = ({ nextStep }) => {
  const {
    setSelectedStep,
    steps,
    reasons,
    selectedReason,
    setSelectedReason,
    userComment,
    setUserComment,
  } = useContext(WebWizardContext)

  return (
    <Container>
      <ContentTitle>So sorry to hear that…</ContentTitle>
      <ContentDescription>
        Would you give us some feedback so we can improve our offering?
      </ContentDescription>
      <ContentContainer>
        <DropdownContainer>
          <DropdownSelect
            className="dropdown"
            selectOptions={reasons}
            selectedOption={selectedReason}
            setSelectedOption={setSelectedReason}
          ></DropdownSelect>
        </DropdownContainer>
        <TextBlockContainer>
          <BlockInput
            placeholder="Leave a comment (optional)"
            setValue={setUserComment}
          ></BlockInput>
        </TextBlockContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button
          color="#1F2040"
          borderColor="#1F2040"
          hoverBackgroundColor="#FC3250"
          className="back"
          onClick={() => {
            setSelectedStep(3)
            nextStep(steps[3])
          }}
        >
          Go back
        </Button>
        <Button
          color="#1F2040"
          borderColor="#1F2040"
          onClick={() => {
            segmentTrack("Step 6", {
              action: "User completed step 6",
              data: {
                reason: selectedReason,
                comment: userComment,
              },
            })
            setSelectedStep(6)
            nextStep(steps[6])
          }}
        >
          Submit
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default WebWizardStep6

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0 20px;
`
const ContentTitle = styled.h1`
  font: normal normal bold 38px/46px Metropolis;
  color: #1f2040;
  width: 100%;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    margin-top: 60px;
  }

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font: normal normal bold 28px/34px Metropolis;
    text-align: center;
  }
`
const ContentDescription = styled.h2`
  font: normal normal normal 21px/35px Metropolis;
  color: #167ffc;
  width: 100%;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font: normal normal normal 16px/30px Metropolis;
    text-align: center;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;

  & > div {
    margin: 10px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    .back {
      order: 2;
    }
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  // flex-wrap: wrap;
`

const DropdownContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
  flex-grow: 1;

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    margin-bottom: 20px;
  }
`
const TextBlockContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 650px;
  justify-content: center;
  height: 250px;
  padding: 0 25px;
  margin-bottom: 50px;
`
