import React from "react"

const CheckMark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.805"
    height="13.398"
    viewBox="0 0 18.805 13.398"
  >
    <path
      id="Icon_feather-check"
      data-name="Icon feather-check"
      d="M21.977,9,10.993,19.984,6,14.991"
      transform="translate(-4.586 -7.586)"
      fill="none"
      stroke="#167ffc"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default CheckMark
