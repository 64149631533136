import React, { useState } from "react"
import styled from "styled-components"
import Checkmark from "../../data/images/icons/checkmark"

const Checkbox = ({
  onClick = () => {},
  className = "",
  startingState = "unchecked",
}) => {
  const [buttonState, setButtonState] = useState(startingState)

  return (
    <Box
      className={`${buttonState} ${className}`}
      onClick={() => {
        setButtonState(buttonState == "checked" ? "unchecked" : "checked")
        onClick()
      }}
    >
      <Checkmark></Checkmark>
    </Box>
  )
}

export default Checkbox

// ====================
//        STYLES
// ====================

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.darkGrey};
  cursor: pointer;
  transition: all 0.3s ease-out;

  svg {
    width: 12px;
    path {
      stroke: ${({ theme }) => theme.darkBlueBorder};
      stroke-width: 3px;
      transition: all 0.3s ease-out;
    }
  }

  @media (min-width: ${({ theme }) => theme.tabletLarge}) {
    &:hover {
      border-color: ${({ theme }) => theme.brightRed};
      background: ${({ theme }) => theme.brightRed};

      svg {
        path {
          stroke: ${({ theme }) => theme.white};
        }
      }
    }
  }

  &.checked {
    border-color: ${({ theme }) => theme.brightRed};
    background: ${({ theme }) => theme.brightRed};

    svg {
      path {
        stroke: ${({ theme }) => theme.white};
      }
    }
  }
`
